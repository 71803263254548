@charset "UTF-8";
@font-face {
  font-family: "Avenir LT W01 45 Book";
  src: url("fonts/4f32268f-fd86-4960-b72c-4bb1ba75ec6f.eot?#iefix");
  src: url("fonts/4f32268f-fd86-4960-b72c-4bb1ba75ec6f.eot?#iefix") format("eot"), url("fonts/939cba03-5b40-4d01-9bc5-7589eca863db.woff") format("woff"), url("fonts/849bc5b9-a2ff-4343-977b-26ba8bd24a60.ttf") format("truetype"), url("fonts/f67fa3b5-c1d1-488f-8e60-a828b9ad56a4.svg#f67fa3b5-c1d1-488f-8e60-a828b9ad56a4") format("svg"); }

@font-face {
  font-family: "Avenir LT W01 95 Black";
  src: url("fonts/a24b3f55-c8c8-41a1-a77e-7cdf417d82f6.eot?#iefix");
  src: url("fonts/a24b3f55-c8c8-41a1-a77e-7cdf417d82f6.eot?#iefix") format("eot"), url("fonts/5a05cf7f-f332-456f-ae67-d8c26e0d35b3.woff") format("woff"), url("fonts/c33278bc-988e-440b-b4be-6acc095e6044.ttf") format("truetype"), url("fonts/7bdad4a5-289b-42f9-b6fa-c5883b9e9f23.svg#7bdad4a5-289b-42f9-b6fa-c5883b9e9f23") format("svg"); }

body {
  font-size: 16px;
  line-height: 20px;
  font-family: "Avenir LT W01 45 Book", Arial, sans-serif;
  background-color: #fff;
  color: #5e5e5e; }

.squeeze {
  width: 75%; }

h1, h2, h3, h4, h5, h6 {
  color: #5e5e5e;
  font-weight: lighter;
  font-family: "Avenir LT W01 45 Book", Arial, sans-serif; }

h1 {
  font-size: 1.8em;
  color: #5e5e5e; }

h2 {
  font-size: 1.6em;
  color: #2f2483; }

img {
  max-width: 100%; }

ul li {
  list-style: disc; }

ul li li {
  list-style: circle; }

ul li li li {
  list-style: square; }

.node .content ul,
.node .content ol {
  margin-left: 40px; }

.container-white {
  background-color: #e40012;
  border-bottom: 2px solid #e40012; }
  .green .container-white {
    background-color: #fff;
    border-bottom: 2px solid #72b639; }
  .orange .container-white {
    background-color: #fff;
    border-bottom: 2px solid #f16f29; }
  .energy-academy .container-white {
    background-color: #fff;
    border-bottom: 2px solid #364A9A; }

.container-white .container-header {
  border: none;
  padding-top: 0; }

.container-white .container-header h3 {
  color: #fff; }

.site-name h3,
.training-title h3 {
  font-size: 30px;
  margin-bottom: 0;
  padding: 0 0 11px 20px;
  color: #fff; }

.container-header {
  height: 80px;
  padding-top: 50px;
  border-bottom: 1px solid #c4c4c4; }

.region-navigation {
  padding-top: 22px; }

.region-navigation .row .block h2 {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px; }

#header {
  margin: 0;
  float: right;
  width: 200px; }

#logo-container {
  height: 50px;
  padding-top: 15px;
  display: inline-block;
  vertical-align: middle; }

#logo-container .st0 {
  fill: none; }

#logo-container .st1 {
  fill: #fff; }

#logo-container img {
  width: 100%;
  height: 100%; }

.site-name,
.training-title {
  display: inline-block;
  vertical-align: bottom;
  float: none; }

#main-wrapper {
  padding: 50px 0;
  padding-bottom: 0; }

#block-views-training-overview-block-block,
#main-wrapper .block-system {
  padding: 10px 0 0; }

#messages {
  margin: 40px 20px 0; }

.big-button,
.form-button-wrapper input,
.form-button-wrapper .button-link,
.button-wrapper .button-link,
.views-field-edit-node a,
.view-trainings .views-field-nothing a,
.inline-button {
  background-color: #e40012;
  color: #fff;
  border-radius: 6px;
  line-height: 100%;
  height: auto;
  padding: 15px;
  text-decoration: none;
  text-transform: initial;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.35s ease; }
  .green .big-button, .green
  .form-button-wrapper input, .green
  .form-button-wrapper .button-link, .green
  .button-wrapper .button-link, .green
  .views-field-edit-node a, .green
  .view-trainings .views-field-nothing a, .green
  .inline-button {
    background-color: #72b639; }
  .orange .big-button, .orange
  .form-button-wrapper input, .orange
  .form-button-wrapper .button-link, .orange
  .button-wrapper .button-link, .orange
  .views-field-edit-node a, .orange
  .view-trainings .views-field-nothing a, .orange
  .inline-button {
    background-color: #f16f29; }
  .energy-academy .big-button, .energy-academy
  .form-button-wrapper input, .energy-academy
  .form-button-wrapper .button-link, .energy-academy
  .button-wrapper .button-link, .energy-academy
  .views-field-edit-node a, .energy-academy
  .view-trainings .views-field-nothing a, .energy-academy
  .inline-button {
    background-color: #364A9A; }

.big-button:hover,
.form-button-wrapper input:hover,
.form-button-wrapper .button-link:hover,
.button-wrapper .button-link:hover,
.views-field-edit-node a:hover,
.view-trainings .views-field-nothing a:hover,
.inline-button:hover {
  background-color: #fd0000; }
  .green .big-button:hover, .green
  .form-button-wrapper input:hover, .green
  .form-button-wrapper .button-link:hover, .green
  .button-wrapper .button-link:hover, .green
  .views-field-edit-node a:hover, .green
  .view-trainings .views-field-nothing a:hover, .green
  .inline-button:hover {
    background-color: #72b639; }
  .orange .big-button:hover, .orange
  .form-button-wrapper input:hover, .orange
  .form-button-wrapper .button-link:hover, .orange
  .button-wrapper .button-link:hover, .orange
  .views-field-edit-node a:hover, .orange
  .view-trainings .views-field-nothing a:hover, .orange
  .inline-button:hover {
    background-color: #f16f29; }
  .energy-academy .big-button:hover, .energy-academy
  .form-button-wrapper input:hover, .energy-academy
  .form-button-wrapper .button-link:hover, .energy-academy
  .button-wrapper .button-link:hover, .energy-academy
  .views-field-edit-node a:hover, .energy-academy
  .view-trainings .views-field-nothing a:hover, .energy-academy
  .inline-button:hover {
    background-color: #364A9A; }

.form-button-wrapper input.disabled {
  background-color: #cecece; }

.button-wrapper {
  margin-bottom: 20px; }

.button-link {
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px; }

/* preload radio-button-icons */
.load-image {
  background-image: url("../images/radio.png"), url("../images/radio_disabled.png");
  display: none; }
  .green .load-image {
    background-image: url("../images/green/radio.png"), url("../images/green/radio_disabled.png"); }
  .orange .load-image {
    background-image: url("../images/orange/radio.png"), url("../images/orange/radio_disabled.png"); }
  .energy-academy .load-image {
    background-image: url("../images/energy-academy/radio.png"), url("../images/energy-academy/radio_disabled.png"); }

#foo:checked,
input.form-checkbox + label::before,
input.form-radio + label::before,
form table input.form-checkbox,
form table input.form-radio {
  height: 20px;
  background: transparent url("../images/radio.png") no-repeat 0 0; }
  .green #foo:checked, .green
  input.form-checkbox + label::before, .green
  input.form-radio + label::before, .green
  form table input.form-checkbox, .green
  form table input.form-radio {
    background: transparent url("../images/green/radio.png") no-repeat 0 0; }
  .orange #foo:checked, .orange
  input.form-checkbox + label::before, .orange
  input.form-radio + label::before, .orange
  form table input.form-checkbox, .orange
  form table input.form-radio {
    background: transparent url("../images/orange/radio.png") no-repeat 0 0; }
  .energy-academy #foo:checked, .energy-academy
  input.form-checkbox + label::before, .energy-academy
  input.form-radio + label::before, .energy-academy
  form table input.form-checkbox, .energy-academy
  form table input.form-radio {
    background: transparent url("../images/energy-academy/radio.png") no-repeat 0 0; }

input.form-checkbox + label::before,
form table input.form-checkbox {
  background: transparent url("../images/checkbox.png") no-repeat 0 0; }
  .green input.form-checkbox + label::before, .green
  form table input.form-checkbox {
    background: transparent url("../images/green/checkbox.png") no-repeat 0 0; }
  .orange input.form-checkbox + label::before, .orange
  form table input.form-checkbox {
    background: transparent url("../images/orange/checkbox.png") no-repeat 0 0; }
  .energy-academy input.form-checkbox + label::before, .energy-academy
  form table input.form-checkbox {
    background: transparent url("../images/energy-academy/checkbox.png") no-repeat 0 0; }

#foo:checked,
input.form-checkbox:checked + label::before,
input.form-radio:checked + label::before {
  background-position: 0 -20px; }

#foo:checked,
input.form-checkbox.disabled + label::before,
input.form-radio.disabled + label::before,
form table input.form-checkbox.disabled,
form table input.form-radio.disabled {
  height: 20px;
  background: transparent url("../images/radio_disabled.png") no-repeat 0 0; }

#foo:checked,
input.form-checkbox.disabled:checked + label::before,
input.form-radio.disabled:checked + label::before {
  background-position: 0 -20px; }

.container-white .container-header [class^="icon-"] {
  background-color: #fff;
  padding: 5px; }
  .green .container-white .container-header [class^="icon-"] {
    background-color: #72b639; }
  .orange .container-white .container-header [class^="icon-"] {
    background-color: #f16f29; }
  .energy-academy .container-white .container-header [class^="icon-"] {
    background-color: #DF2127; }

.container-white .container-header [class^="icon-"] {
  color: transparent;
  margin: 0 8px 0 0;
  padding: 0; }

.region-navigation a[class*="icon-"],
.region-navigation span.no-link,
.region-navigation span.nolink {
  overflow: visible; }

.region-navigation a.icon-menu {
  overflow: hidden; }

.region-navigation a.icon-menu .icon-inner {
  margin-right: 0; }

.region-navigation ul.nice-menu-down li a,
.region-navigation ul.nice-menu-down li.menuparent a,
.container-white .container-header [class^="icon-"] {
  padding: 8px 0;
  margin-right: 4px; }

.region-navigation a[class*="icon-"] svg,
.region-navigation span.no-link svg,
.region-navigation span.nolink svg {
  fill: #e40012;
  max-height: none;
  min-width: 15px;
  height: 15px;
  max-width: none; }
  .green .region-navigation a[class*="icon-"] svg, .green
  .region-navigation span.no-link svg, .green
  .region-navigation span.nolink svg {
    fill: #72b639; }
  .orange .region-navigation a[class*="icon-"] svg, .orange
  .region-navigation span.no-link svg, .orange
  .region-navigation span.nolink svg {
    fill: #f16f29; }
  .energy-academy .region-navigation a[class*="icon-"] svg, .energy-academy
  .region-navigation span.no-link svg, .energy-academy
  .region-navigation span.nolink svg {
    fill: #fff; }

.region-navigation .disabled a[class*="icon-"] svg,
.region-navigation .disabled span.no-link svg,
.region-navigation .disabled span.nolink svg {
  fill: rgba(253, 0, 0, 0.1); }

.container-white .container-header [class^="icon-"]:before {
  color: #e40012;
  margin: 0;
  padding: 0;
  font-size: 22px;
  top: 4px;
  transition: color .35s ease; }
  .green .container-white .container-header [class^="icon-"]:before {
    color: #72b639; }
  .orange .container-white .container-header [class^="icon-"]:before {
    color: #f16f29; }
  .energy-academy .container-white .container-header [class^="icon-"]:before {
    color: #fff; }

.container-white .container-header [class^="icon-"]:hover:before,
.container-white .container-header [class^="icon-"]:focus:before {
  color: #d50000; }
  .green .container-white .container-header [class^="icon-"]:hover:before, .green
  .container-white .container-header [class^="icon-"]:focus:before {
    fill: #72b639; }
  .orange .container-white .container-header [class^="icon-"]:hover:before, .orange
  .container-white .container-header [class^="icon-"]:focus:before {
    fill: #f16f29; }
  .energy-academy .container-white .container-header [class^="icon-"]:hover:before, .energy-academy
  .container-white .container-header [class^="icon-"]:focus:before {
    fill: #364A9A; }

.block-nice-menus ul.nice-menu li {
  margin: 0; }

.block-nice-menus ul.nice-menu li ul {
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  left: -85px;
  top: 0;
  margin-top: 45px;
  border-radius: 3px; }

.block-nice-menus ul.nice-menu li ul:before {
  content: '';
  position: absolute;
  left: 50%;
  background-image: url(../images/arrow.png);
  background-repeat: no-repeat;
  margin-left: -25px;
  width: 39px;
  height: 23px;
  top: -23px; }

.block-nice-menus ul.nice-menu li ul li {
  background-color: #fff;
  padding: 0;
  border: 2px solid white;
  border-bottom: 1px solid #f4f4f4;
  border-top: none;
  border-top: 0;
  width: 204px; }

.block-nice-menus ul.nice-menu li ul li.last {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 5px; }

#block-nice-menus-1.block-nice-menus ul.nice-menu li ul li a {
  border-bottom: 0;
  background: transparent;
  color: #232323;
  font-size: 15px;
  font-weight: 100;
  padding: 15px 20px !important;
  margin: 0; }

#block-nice-menus-1.block-nice-menus ul.nice-menu li ul li a:focus,
#block-nice-menus-1.block-nice-menus ul.nice-menu li ul li a:hover {
  text-decoration: none;
  background-color: #f4f4f4; }

.block-nice-menus ul.nice-menu li ul li a:hover,
.block-nice-menus ul.nice-menu li ul li a:focus {
  background-color: #f4f4f4; }

#training-index-button {
  margin: 0; }

.collapsible-wrapper .toggle-collapse {
  position: static;
  float: left;
  margin: 5px 5px 0 0; }

.collapsible-wrapper .fieldset-title {
  background: url(../images/expanded.png) no-repeat left center !important; }

.collapsible-wrapper.collapsed .fieldset-title {
  background: url(../images/collapsed.png) no-repeat left center !important; }

#block-ys-elearning-custom-register-block,
.collapsible-wrapper {
  border-bottom: 1px dotted #000001; }

.front #block-system-main h2,
#block-ys-elearning-custom-register-block h2 {
  color: #000001; }

#user-register-form label {
  color: #5e5e5e; }

.group-media .field {
  border-color: #e40012; }
  .green .group-media .field {
    border-color: #72b639; }
  .orange .group-media .field {
    border-color: #f16f29; }
  .energy-academy .group-media .field {
    border-color: #364A9A; }

.group-media .field:hover {
  border-color: #2f2483; }
  .green .group-media .field:hover {
    border-color: #72b639; }
  .orange .group-media .field:hover {
    border-color: #f16f29; }
  .energy-academy .group-media .field:hover {
    border-color: #364A9A; }

a.media-button svg {
  fill: #e40012; }
  .green a.media-button svg {
    fill: #72b639; }
  .orange a.media-button svg {
    fill: #f16f29; }
  .energy-academy a.media-button svg {
    fill: #364A9A; }

.group-media .field:hover svg {
  fill: #2f2483; }
  .green .group-media .field:hover svg {
    fill: #72b639; }
  .orange .group-media .field:hover svg {
    fill: #f16f29; }
  .energy-academy .group-media .field:hover svg {
    fill: #364A9A; }

.group-media {
  margin-top: -15px;
  margin-right: -15px;
  display: inline;
  position: absolute;
  right: 0 !important;
  width: auto;
  float: right; }

.group-media .field {
  width: 56px;
  height: 56px;
  border-radius: 50%; }

.group-media svg {
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px; }

.html5-audio-player .custom_play:before {
  content: '\e805';
  font-size: 35px !important;
  padding-top: 10px;
  height: 38px; }

.html5-audio-player .custom_play:hover:before,
.html5-audio-player .custom_play:focus:before {
  content: '\e802';
  padding-left: 4px;
  padding-top: 12px !important;
  font-size: 30px !important; }

.playing .custom_play:hover:before,
.playing .custom_play:focus:before {
  content: '\e80a';
  padding-left: 1px;
  font-size: 28px !important;
  padding-top: 14px !important;
  height: 38px; }

.html5-audio-player .paused:hover:before {
  content: '\e802';
  font-size: 26px;
  padding-top: 12px;
  height: 38px; }

.html5-audio-player .paused:focus:before {
  content: '\e802';
  font-size: 26px;
  padding-top: 12px;
  height: 38px; }

.group-media .jw_player_video .custom_play:before {
  content: '\e850';
  padding-left: 2px; }

.group-media .jw_player_video .custom_play:hover:before {
  content: '\e802';
  padding-left: 4px; }

.group-media .jw_player_video .custom_play:focus:before {
  content: '\e802'; }

.group-media .jw_player_video .custom_play:focus:before {
  content: '\e802'; }

.field-name-field-video .jwplayer-video {
  margin: 0 auto; }

.formatter-jw_player .jwplayer-video {
  box-shadow: 0px 0px 3px 0px rgba(50, 50, 50, 0.75); }

.formatter-jw_player .jwplayer-video [id^=videoplayer-preset-] {
  height: 100%; }

.formatter-jw_player .video-caption {
  width: 620px;
  margin: 10px auto 0; }

.field-name-field-information-area a {
  padding-left: 1px; }

div.info-icon-wrapper a {
  overflow: hidden;
  padding-top: 12px; }

div.info-icon-wrapper-small a {
  padding-top: 6px !important; }

.icon-link,
.icon-link:before {
  text-decoration: none; }

.block-ys-elearning-index .content {
  float: right; }

.block-ys-elearning-index .index-menu {
  margin-right: 4px; }

.html5-audio-element {
  position: relative; }

.html5-audio-element canvas {
  position: relative;
  top: -52px;
  z-index: -100;
  left: -2px; }

#fancybox-content #information-area {
  padding: 10px; }

#information-block {
  padding-top: 20px; }

#fancybox-content #information-block {
  overflow: hidden; }

body.fancybox {
  padding: 20px;
  overflow-x: hidden; }

.tipsy-inner img {
  width: 100%;
  margin-bottom: 10px; }

.tipsy-inner {
  max-width: none !important; }

.tipsy-inner a,
body .tooltipster-default a {
  color: #fff;
  text-decoration: underline !important; }

li.tipsy-title {
  max-width: 500px; }

/* Need to know */
.group-media .need_to_know:before {
  display: block;
  height: 18px;
  width: 18px;
  background: url(../images/need_to-know.png) no-repeat;
  position: relative;
  float: right;
  content: " ";
  display: block;
  margin-top: -2px;
  margin-bottom: -20px;
  left: 5px;
  z-index: 1000 !important; }

.tooltipster-default,
.tooltipster-default a {
  background: #000;
  border: none;
  color: #fff;
  font-size: 1em !important;
  line-height: 1.5em !important;
  text-decoration: none !important; }

.tooltipster-content .jwplayer-video {
  height: 54px;
  width: auto; }

.tooltipster-default a:hover {
  text-decoration: underline; }

.tooltipster-content ul {
  list-style: disc;
  padding: 1em; }

.tooltipster-content .field-item span.need_to_know {
  padding-right: 18px;
  background: url(../images/ntn_file.png) no-repeat right center; }

.hidden-flash {
  display: none; }

.turnjs-navigation {
  display: block;
  margin-top: 20%;
  height: 125px !important;
  width: 65px !important;
  transition: opacity .10s ease-in-out;
  -moz-transition: opacity .10s ease-in-out;
  -webkit-transition: opacity .10s ease-in-out;
  opacity: .3; }

.bg-hidden {
  background-image: none !important; }

.previous,
.drupal-turnjs {
  float: left; }

.drupal-turnjs .page {
  background-color: #ccc;
  background-size: 100% 100%; }

.pager li {
  display: inline-block;
  list-style: none; }

.export-training-link {
  margin-top: 10px; }

.export-training-link a {
  margin: 0 1.5em; }

.note {
  color: #e40012; }

.block-ys-elearning-index .tab-wrapper,
.block-ys-elearning-index .tab-content {
  background: #fff;
  padding: 0; }

.block-ys-elearning-index .tab-wrapper {
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  margin-left: 10px;
  padding: 0 20px 20px;
  width: 371px; }

.block-ys-elearning-index .tab-content {
  border: none; }

.single .tab-content {
  padding-top: 0; }

#training-index ul li a:hover {
  background: #f4f4f4; }

.block-ys-elearning-index .tab-content-list {
  background: #fff;
  color: #5e5e5e; }

.block-ys-elearning-index #training-definitions .item-list li:hover,
.block-ys-elearning-index #training-index .item-list li:hover,
.block-ys-elearning-index #training-definitions .item-list li:focus,
.block-ys-elearning-index #training-index .item-list li:focus {
  background: #fff; }

#training-index .item-list li .disabled,
#training-index .item-list li a {
  border-top: 0;
  border-bottom: 1px solid #f4f4f4;
  font-size: 15px !important;
  font-weight: 100;
  padding: 15px 20px !important; }

#training-index .item-list li a {
  color: #232323; }

#training-index .item-list li .disabled span,
#training-index .item-list li a span {
  display: table-cell;
  padding-right: 5px; }

.block-ys-elearning-index .tab-content-list a:hover,
.block-ys-elearning-index .tab-content-list a:focus {
  background-color: #f4f4f4; }

#training-index .item-list li.active a {
  font-family: "Avenir LT W01 45 Book";
  background-image: url(../images/check-symbol.svg);
  background-position: right 20px top 21px;
  padding-right: 30px !important; }

.list-items {
  max-height: 70vh !important; }

.block-ys-elearning-index h2 {
  color: #e40012;
  border-top: none;
  border-bottom: none; }

.block-ys-elearning-index .filterinput {
  background: #f5f5f5;
  box-shadow: none;
  text-shadow: none;
  padding: 15px 20px !important;
  color: #333;
  border-radius: 3px;
  width: 330px; }

.block-ys-elearning-index .filterinput:focus {
  outline: initial;
  border: none; }

.block-ys-elearning-index .menu_arrow {
  background-image: url(../images/arrow.png);
  background-repeat: no-repeat;
  width: 36px;
  height: 27px;
  bottom: 23px;
  left: 306px; }

.list-search {
  width: 350px;
  margin-left: 0;
  margin-right: 0; }

.flag-wrapper {
  max-width: 1280px;
  margin: 0px auto; }

.information-page-flag {
  margin-top: 60px;
  margin-bottom: 60px;
  margin-right: 2%;
  float: left;
  width: 23%; }

.information-page-flag-last {
  margin-right: 0; }

.node-information-page .field-name-body a[href^="select-training"] img {
  max-width: 100%; }

.wrapper-item-blocks {
  max-width: 1280px;
  margin: 0 auto; }

.title-block-menu {
  width: 385px;
  display: inline-block; }

.menu-item-block {
  display: inline-block;
  padding: 10px;
  height: 204px;
  width: 610px;
  margin: 0 10px 15px 0;
  border: 2px solid #e40012;
  float: left; }

.menu-item-block:nth-of-type(2n+2) {
  margin-right: 0; }

.image-index-200 {
  width: 200px;
  height: 200px;
  margin-right: 20px;
  float: left; }

.index-hide-text {
  width: 380px;
  height: 150px;
  overflow: hidden;
  overflow-y: scroll; }

.button-inline {
  background-color: #e40012;
  background: linear-gradient(to bottom, #e40012 0, #fd0010 100%); }
  .green .button-inline {
    background-color: #72b639;
    background: #72b639; }
  .orange .button-inline {
    background-color: #f16f29;
    background: #f16f29; }
  .energy-academy .button-inline {
    background-color: #364A9A;
    background: #364A9A; }

.button-inline:hover,
.button-inline:focus {
  background: linear-gradient(to bottom, #e40012 0, #fd0010 100%); }
  .green .button-inline:hover, .green
  .button-inline:focus {
    background-color: #72b639; }
  .orange .button-inline:hover, .orange
  .button-inline:focus {
    background-color: #f16f29; }
  .energy-academy .button-inline:hover, .energy-academy
  .button-inline:focus {
    background-color: #364A9A; }

.hover-item:hover,
.hover-item:focus,
.hover-item.hover {
  border: 2px solid #e40012; }
  .green .hover-item:hover, .green
  .hover-item:focus, .green
  .hover-item.hover {
    border-color: #72b639; }
  .orange .hover-item:hover, .orange
  .hover-item:focus, .orange
  .hover-item.hover {
    border-color: #f16f29; }
  .energy-academy .hover-item:hover, .energy-academy
  .hover-item:focus, .energy-academy
  .hover-item.hover {
    border-color: #364A9A; }

.text-accent {
  color: #e40012;
  font-style: italic; }
  .green .text-accent {
    color: #72b639; }
  .orange .text-accent {
    color: #f16f29; }
  .energy-academy .text-accent {
    color: #364A9A; }

/* ================================ Media Queries =========================== */
/**
 * Tablets
 */
@media screen and (max-width: 1280px) {
  .wrapper-item-blocks {
    max-width: 655px; }
  .menu-item-block {
    width: 630px; }
  .title-block-menu {
    width: 405px; }
  .index-hide-text {
    width: 400px; } }

@media screen and (max-width: 1000px) {
  .squeeze {
    width: 746px; }
  #block-system-main .group-media {
    top: 108px !important; }
  h1.title {
    width: 60%; }
  #header,
  .region-navigation {
    width: auto !important; }
  #block-system-main .field-group-tabs-wrapper {
    margin-top: -65px !important; }
  .field-name-body div[style],
  .field-name-body img {
    max-width: 100% !important; }
  body.not-logged-in.register-block #block-ys-elearning-custom-register-block {
    padding-left: 50px; }
  body.not-logged-in #block-ys-elearning-custom-register-block,
  body.not-logged-in.register-block #block-system-main {
    width: 100% !important;
    margin-right: 0;
    padding-right: 0px;
    float: none; }
  .menu-item-block:nth-of-type(1n+2) {
    margin-right: 0; } }

@media screen and (max-width: 768px) {
  .wrapper-item-blocks {
    width: 565px; }
  .menu-item-block {
    width: 540px;
    margin-right: 0; }
  .title-block-menu {
    width: 315px; }
  .index-hide-text {
    width: 310px; } }

@media only screen and (max-width: 767px) {
  .squeeze {
    max-width: 90%;
    width: auto; }
  .container-white {
    margin-bottom: 40px; }
  .container-header {
    height: auto;
    padding-top: 20px; }
  #header {
    position: inherit;
    width: 100px;
    margin: 0;
    margin-right: 20px;
    margin-top: -8px; }
  #logo-container {
    line-height: 0;
    margin-bottom: 10px;
    float: none;
    height: auto; }
  h1.title {
    width: 100% !important; }
  .site-name,
  .training-title {
    width: auto; }
  .site-name h3,
  .training-title h3 {
    margin: 0 0 11px 5px; }
  #block-system-main .field-group-tabs-wrapper {
    margin-top: 0 !important;
    float: none;
    position: inherit;
    margin-bottom: 20px;
    display: block; }
  .group-media .field, div.info-icon-wrapper {
    margin-left: 0 !important; }
  #main-wrapper {
    padding-top: 0; }
  .field-name-field-video,
  .field-name-field-information-area {
    bottom: 0; }
  .field-name-body div[style],
  .field-name-body img {
    max-width: 100% !important; }
  #messages {
    margin: 0; }
  .view-training-overview .views-row {
    width: 100% !important; }
  body.not-logged-in.register-block #main-wrapper,
  body.not-logged-in.register-block #block-ys-elearning-custom-register-block,
  body.not-logged-in.register-block #block-system-main {
    padding-left: 0 !important;
    margin-left: 0 !important; }
  .menu-item-block {
    width: 480px;
    margin-right: 0; }
  .title-block-menu {
    width: 255px; }
  .index-hide-text {
    width: 250px; } }

@media screen and (max-width: 640px) {
  .video-caption {
    width: 290px; }
  .information-page-flag {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    width: 100%; }
  .menu-item-block {
    width: 440px; }
  .title-block-menu {
    width: 210px; }
  .index-hide-text {
    width: 205px; } }

/**
 * Forms
 */
input[type=text]:focus,
input[type=email]:focus,
select.form-select:focus,
input[type=password]:focus {
  border-color: #e40012;
  outline-color: #ccc; }

.container-inline-date select.form-select {
  display: inline; }

#ui-datepicker-div {
  background: #eee;
  border: 1px #9b9b9b solid;
  padding: .2em .0em 0; }

#ui-datepicker-div a {
  color: #333;
  text-align: center;
  padding: 4px; }

#ui-datepicker-div.ui-widget-content .ui-icon {
  background-image: url(/misc/ui/images/ui-icons_222222_256x240.png);
  width: 16px;
  height: 16px; }

#ui-datepicker-div .ui-icon-circle-triangle-w {
  background-position: -80px -192px; }

.ui-icon-circle-triangle-e {
  background-position: -48px -192px; }

#ys-rest-certificate-test-page .form-item {
  max-width: 400px; }

label[for=edit-question-answer],
label[for*=edit-question-answer] {
  color: #2f2483;
  font-size: 1.2em; }

.form-item-question-answer label {
  font-size: 1.1em; }

.form-item-question-answer .form-type-radio label,
.form-item-question-answer .form-checkboxes label {
  max-width: 80%; }

/* Turn js */
.turn_js {
  position: relative; }

.turn_js .page {
  background: #fff; }

.highlight {
  display: block;
  position: absolute;
  box-shadow: 0px 0px 14px red;
  border-radius: 3px;
  behavior: url(PIE.htc); }

.highlight {
  -webkit-animation: play 0.8s steps(25) infinite;
  -moz-animation: play 0.8s steps(25) infinite;
  -ms-animation: play 0.8s steps(25) infinite;
  -o-animation: play 0.8s steps(25) infinite;
  animation: play 0.8s steps(25) infinite; }

@-webkit-keyframes play {
  0% {
    box-shadow: 0 0 1px red; }
  50% {
    box-shadow: 0 0 15px red; }
  100% {
    box-shadow: 0 0 1px red; } }

@-moz-keyframes play {
  0% {
    box-shadow: 0 0 1px red; }
  50% {
    box-shadow: 0 0 15px red; }
  100% {
    box-shadow: 0 0 1px red; } }

@-ms-keyframes play {
  0% {
    box-shadow: 0 0 1px red; }
  50% {
    box-shadow: 0 0 15px red; }
  100% {
    box-shadow: 0 0 1px red; } }

@-o-keyframes play {
  0% {
    box-shadow: 0 0 1px red; }
  50% {
    box-shadow: 0 0 15px red; }
  100% {
    box-shadow: 0 0 1px red; } }

@keyframes play {
  0% {
    box-shadow: 0 0 1px red; }
  50% {
    box-shadow: 0 0 15px red; }
  100% {
    box-shadow: 0 0 1px red; } }

.view-training-overview .views-row a {
  color: #fff;
  display: block;
  font-size: 16px;
  background-color: #E7131A;
  width: 160px;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
  border-radius: 4px; }
  .green .view-training-overview .views-row a {
    background-color: #72b639; }
  .orange .view-training-overview .views-row a {
    background-color: #f16f29; }
  .energy-academy .view-training-overview .views-row a {
    background-color: #364A9A; }

body.training-overview #block-system-main {
  padding: 0;
  margin-bottom: 40px; }

body.training-overview #messages {
  margin-left: 0px; }

body.page-admin-people #block-system-main {
  padding-top: 30px; }

.filter-block,
#views-form-admin-views-user-system-1,
.view-question-overview .ctools-collapsible-handle {
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  background: #f8f8f8;
  margin: 50px 50px 0; }

.filter-block h2,
.table-head,
.view-question-overview .ctools-collapsible-handle h2 {
  text-shadow: 0px 1px 0px #fff;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 1px solid #c4c4c4;
  padding: 10px 20px 8px;
  background: #efefef;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VmZWZlZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjI4JSIgc3RvcC1jb2xvcj0iI2U3ZTdlNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijg0JSIgc3RvcC1jb2xvcj0iI2QxZDFkMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijk4JSIgc3RvcC1jb2xvcj0iI2NlY2VjZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjN2M3YzciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #efefef 0%, #e7e7e7 28%, #d1d1d2 84%, #cececf 98%, #c7c7c7 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #efefef), color-stop(28%, #e7e7e7), color-stop(84%, #d1d1d2), color-stop(98%, #cececf), color-stop(100%, #c7c7c7));
  background: -webkit-linear-gradient(top, #efefef 0%, #e7e7e7 28%, #d1d1d2 84%, #cececf 98%, #c7c7c7 100%);
  background: -o-linear-gradient(top, #efefef 0%, #e7e7e7 28%, #d1d1d2 84%, #cececf 98%, #c7c7c7 100%);
  background: -ms-linear-gradient(top, #efefef 0%, #e7e7e7 28%, #d1d1d2 84%, #cececf 98%, #c7c7c7 100%);
  background: linear-gradient(to bottom, #efefef 0%, #e7e7e7 28%, #d1d1d2 84%, #cececf 98%, #c7c7c7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efefef', endColorstr='#c7c7c7',GradientType=0 ); }

.view-question-overview .ctools-collapsible-handle {
  margin: 20px 0 0; }

.view-question-overview .ctools-toggle {
  background-image: none; }

.view-question-overview .ctools-collapsible-content fieldset.views-fieldset {
  margin-bottom: 0;
  border-bottom: none; }

.view-question-overview .ctools-collapsible-content fieldset.views-fieldset .fieldset-legend {
  line-height: 15px;
  padding-bottom: 0.1em; }

.block-collapse {
  float: right; }

.filter-block form {
  width: 620px;
  margin: 0 auto;
  padding-top: 45px; }

.filter-block form label {
  display: inline-block;
  width: 150px;
  font-weight: normal;
  vertical-align: top; }

.filter-block form .views-widget {
  display: inline-block; }

.filter-block #edit-name-wrapper input,
.filter-block #edit-mail-wrapper input,
.filter-block #edit-uid-wrapper input {
  width: 438px !important;
  border-radius: 3px; }

.filter-block #edit-name-wrapper input:focus,
.filter-block #edit-mail-wrapper input:focus,
.filter-block #edit-uid-wrapper input:focus {
  outline: none !important; }

.filter-block form .views-widget select {
  width: 462px !important; }

.filter-block form .form-actions {
  float: left;
  margin-left: 155px; }

.filter-block .description {
  display: none; }

.views-reset-button {
  float: left; }

.views-reset-button input {
  background: #c8c8c8; }

#views-form-admin-views-user-system-1,
.view-trainings {
  margin: 0;
  border: none; }

#views-form-admin-views-user-system-1 thead,
.view-trainings thead {
  background: #eee;
  border: 1px solid #c4c4c4;
  border-left: none;
  border-right: none;
  text-shadow: 0px 1px 0px #fff; }

#views-form-admin-views-user-system-1 thead th,
#views-form-admin-views-user-system-1 thead th a,
.view-trainings thead th,
.view-trainings thead th a {
  color: #5e5e5e;
  text-decoration: none;
  font-weight: bold; }

#views-form-admin-views-user-system-1 td,
.view-trainings td {
  background-color: #fff;
  padding: 20px 10px; }

.table-head {
  border: 1px solid #c4c4c4; }

a.block-collapse:before,
a.block-collapse {
  color: #7a7a7a;
  text-decoration: none; }

.expanded a.block-collapse:before {
  content: '▾'; }

.collapsed a.block-collapse:before {
  content: '▸'; }

#views-form-admin-views-user-system-1 table .views-field-name a,
.view-trainings table .views-field-name a {
  text-decoration: none;
  display: block; }

.view-trainings .training-failed {
  color: #e40012; }

.chosen-container-multi .chosen-choices,
.chosen-container-single .chosen-single {
  border-radius: 3px;
  border: 1px solid #ccc;
  box-shadow: none;
  background: #fff;
  min-height: 30px; }

.chosen-container-single .chosen-single {
  padding-top: 3px; }

.chosen-container-multi .chosen-choices,
#edit_rid_chosen.chosen-container .search-field input,
#edit_status_chosen.chosen-container .search-field input,
.form-item-tnid .search-field input {
  min-height: 29px !important; }

.chosen-container-single .chosen-single div b {
  background-position: 0 5px; }

.chosen-container .chosen-drop {
  box-shadow: none; }

.chosen-container.chosen-container-active .chosen-single {
  box-shadow: none;
  background: #fff; }

.chosen-container.chosen-container-active .chosen-single div b {
  background-position: -18px 5px; }

#views-exposed-form-trainings-page .views-submit-button {
  width: 100%; }

#views-exposed-form-trainings-page .views-exposed-widget .container-inline-date {
  width: 225px; }

#views-exposed-form-trainings-page .container-inline-date label {
  width: 10px;
  right: 28px;
  top: 8px; }

#views-exposed-form-trainings-page .views-widget-filter-tnid {
  margin-bottom: 1.3em; }

#views-exposed-form-trainings-page .views-widget-filter-tnid .form-item-tnid {
  margin-bottom: 0em; }

#views-exposed-form-trainings-page .trainings-select-all,
#views-exposed-form-trainings-page .trainings-deselect-all {
  font-size: .9rem;
  margin-right: 10px; }

.form-item-timestamp-max-date input,
.form-item-timestamp-min-date input {
  width: 172px !important;
  border-radius: 3px; }

.filter-block #edit-uid-wrapper input {
  width: 408px !important; }

#edit-timestamp-wrapper > label:first-child {
  margin-top: -20px; }

body.register-block #block-ys-elearning-custom-register-block.disable_login {
  width: 100%; }

.disable_login fieldset {
  padding: 0; }

.disable_login .field-type-text,
.disable_login .field-type-datestamp,
.disable_login .field-type-list-text {
  float: left;
  width: 45%;
  margin-right: 4%; }

.filterinput {
  border: none !important;
  width: 342px; }

a.reset-link {
  display: inline-block;
  border: 1px solid #e40012;
  height: 42px;
  box-sizing: border-box;
  margin-top: -20px;
  margin-bottom: 30px;
  vertical-align: text-bottom;
  text-decoration: none;
  min-width: 220px; }

a.reset-link:before {
  content: ' ';
  display: inline;
  float: left;
  background: #e40012;
  width: 42px;
  height: 40px;
  background-image: url(../images/refresh.svg);
  background-repeat: no-repeat;
  background-position: 4px center;
  background-size: 30px; }

a.reset-link .text {
  text-align: center;
  color: #e40012;
  display: block;
  padding-top: 10px;
  text-decoration: none;
  text-transform: uppercase; }

.big-button {
  font-size: 22px; }

.uniper-user-validate .form-actions {
  clear: left; }

.certificate-link {
  margin-right: 10px; }

.register-block #block-system-main label,
.register-block #block-ys-elearning-custom-register-block label {
  vertical-align: middle; }

#training-wrapper {
  margin: 0 20px; }

#training-wrapper h2 {
  margin-top: 0; }

#training-wrapper .field-group-tabs-wrapper {
  margin-top: 15px; }

.form-item-question-answer .form-item label {
  padding-top: 0; }

.view-training-overview-block .views-row {
  margin: 0 18px 0 0; }

.view-training-overview-block .views-rows {
  margin: 0; }

.view-training-overview-block .views-category-row h3 {
  margin-left: 0; }

.view-training-overview-block .views-category-row {
  margin-left: 22px; }

.answer-correct {
  color: green; }

.answer-failed {
  color: #e40012; }

.h5p-iframe-wrapper {
  max-width: 1024px;
  height: auto;
  margin: 0 auto; }

.h5p-course-presentation .h5p-progressbar .h5p-progressbar-part-show {
  background: #e40012; }
  .green .h5p-course-presentation .h5p-progressbar .h5p-progressbar-part-show {
    background: #72b639; }
  .orange .h5p-course-presentation .h5p-progressbar .h5p-progressbar-part-show {
    background: #f16f29; }
  .energy-academy .h5p-course-presentation .h5p-progressbar .h5p-progressbar-part-show {
    background: #364A9A; }

.h5p-course-presentation .h5p-element-button {
  background: #e40012;
  border: none; }
  .green .h5p-course-presentation .h5p-element-button {
    background: #72b639; }
  .orange .h5p-course-presentation .h5p-element-button {
    background: #f16f29; }
  .energy-academy .h5p-course-presentation .h5p-element-button {
    background: #364A9A; }

.h5p-course-presentation .h5p-presentation-wrapper {
  background: #fff; }

.h5p-question-explanation-correct {
  color: #72b639; }

.h5p-question-explanation-wrong {
  color: #EF2751; }
  .energy-academy .h5p-question-explanation-wrong {
    color: #DF2127; }

.h5p-summary .summary-container li:before {
  color: #72b639; }

.h5p-summary .h5p-panel:not(.panel-disabled) .summary-claim-unclicked:hover {
  background: #ececec; }

.h5p-summary li.summary-failed > p,
.h5p-summary li.summary-failed {
  color: #EF2751;
  border: 0.1em solid #fde5ea;
  background: #fde5ea; }
  .energy-academy .h5p-summary li.summary-failed > p, .energy-academy
  .h5p-summary li.summary-failed {
    background: #DF2127;
    border: 0.1em solid #f9d3d4;
    background: #f9d3d4; }

.h5p-summary li.summary-failed:before {
  color: #EF2751; }
  .energy-academy .h5p-summary li.summary-failed:before {
    color: #DF2127; }

.h5p-summary .summary-score {
  background: #f7d0d0;
  background: #fde5ea; }
  .energy-academy .h5p-summary .summary-score {
    background: #f9d3d4; }

.h5p-summary .summary-score:before {
  color: #EF2751; }
  .energy-academy .h5p-summary .summary-score:before {
    color: #DF2127; }

.h5p-summary .summary-progress-numeric {
  background: #ffb1b7; }

.h5p-summary .summary-progress-numeric:before {
  color: #e40012; }

.h5peditor .ui-dialog .h5p-joubelui-button,
.h5peditor .h5p-joubelui-button,
.h5p-joubelui-button {
  background: #e40012;
  color: #ffffff; }
  .h5peditor .ui-dialog .h5p-joubelui-button:hover, .h5peditor .ui-dialog .h5p-joubelui-button:focus,
  .h5peditor .h5p-joubelui-button:hover,
  .h5peditor .h5p-joubelui-button:focus,
  .h5p-joubelui-button:hover,
  .h5p-joubelui-button:focus {
    background: #7e000a; }
  .green .h5peditor .ui-dialog .h5p-joubelui-button, .green
  .h5peditor .h5p-joubelui-button, .green
  .h5p-joubelui-button {
    background: #72b639; }
    .green .h5peditor .ui-dialog .h5p-joubelui-button:hover, .green .h5peditor .ui-dialog .h5p-joubelui-button:focus, .green
    .h5peditor .h5p-joubelui-button:hover, .green
    .h5peditor .h5p-joubelui-button:focus, .green
    .h5p-joubelui-button:hover, .green
    .h5p-joubelui-button:focus {
      background: #416821; }
  .orange .h5peditor .ui-dialog .h5p-joubelui-button, .orange
  .h5peditor .h5p-joubelui-button, .orange
  .h5p-joubelui-button {
    background: #f16f29; }
    .orange .h5peditor .ui-dialog .h5p-joubelui-button:hover, .orange .h5peditor .ui-dialog .h5p-joubelui-button:focus, .orange
    .h5peditor .h5p-joubelui-button:hover, .orange
    .h5peditor .h5p-joubelui-button:focus, .orange
    .h5p-joubelui-button:hover, .orange
    .h5p-joubelui-button:focus {
      background: #a9420b; }
  .energy-academy .h5peditor .ui-dialog .h5p-joubelui-button, .energy-academy
  .h5peditor .h5p-joubelui-button, .energy-academy
  .h5p-joubelui-button {
    background: #364A9A; }
    .energy-academy .h5peditor .ui-dialog .h5p-joubelui-button:hover, .energy-academy .h5peditor .ui-dialog .h5p-joubelui-button:focus, .energy-academy
    .h5peditor .h5p-joubelui-button:hover, .energy-academy
    .h5peditor .h5p-joubelui-button:focus, .energy-academy
    .h5p-joubelui-button:hover, .energy-academy
    .h5p-joubelui-button:focus {
      background: #1c264e; }

.h5p-interactive-video div > .h5p-interaction-button {
  background-color: #e40012; }
  .h5p-interactive-video div > .h5p-interaction-button:hover, .h5p-interactive-video div > .h5p-interaction-button:focus {
    background-color: #7e000a; }
  .green .h5p-interactive-video div > .h5p-interaction-button {
    background: #72b639; }
    .green .h5p-interactive-video div > .h5p-interaction-button:hover, .green .h5p-interactive-video div > .h5p-interaction-button:focus {
      background-color: #416821; }
  .orange .h5p-interactive-video div > .h5p-interaction-button {
    background-color: #f16f29; }
    .orange .h5p-interactive-video div > .h5p-interaction-button:hover, .orange .h5p-interactive-video div > .h5p-interaction-button:focus {
      background-color: #a9420b; }
  .energy-academy .h5p-interactive-video div > .h5p-interaction-button {
    background-color: #364A9A; }
    .energy-academy .h5p-interactive-video div > .h5p-interaction-button:hover, .energy-academy .h5p-interactive-video div > .h5p-interaction-button:focus {
      background-color: #1c264e; }

.h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button,
.h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button,
.h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button,
.h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button {
  background-color: #e40012; }
  .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:hover, .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:focus,
  .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:hover,
  .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:focus,
  .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:hover,
  .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:focus,
  .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:hover,
  .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:focus {
    background-color: #7e000a; }
  .green .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button, .green
  .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button, .green
  .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button, .green
  .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button {
    background: #72b639; }
    .green .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:hover, .green .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:focus, .green
    .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:hover, .green
    .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:focus, .green
    .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:hover, .green
    .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:focus, .green
    .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:hover, .green
    .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:focus {
      background-color: #416821; }
  .orange .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button, .orange
  .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button, .orange
  .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button, .orange
  .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button {
    background-color: #f16f29; }
    .orange .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:hover, .orange .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:focus, .orange
    .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:hover, .orange
    .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:focus, .orange
    .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:hover, .orange
    .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:focus, .orange
    .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:hover, .orange
    .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:focus {
      background-color: #a9420b; }
  .energy-academy .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button, .energy-academy
  .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button, .energy-academy
  .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button, .energy-academy
  .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button {
    background-color: #364A9A; }
    .energy-academy .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:hover, .energy-academy .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:focus, .energy-academy
    .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:hover, .energy-academy
    .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:focus, .energy-academy
    .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:hover, .energy-academy
    .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:focus, .energy-academy
    .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:hover, .energy-academy
    .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:focus {
      background-color: #1c264e; }

.h5p-single-choice-set .h5p-joubelui-progressbar-background {
  background-color: #e40012; }
  .green .h5p-single-choice-set .h5p-joubelui-progressbar-background {
    background-color: #72b639; }
  .orange .h5p-single-choice-set .h5p-joubelui-progressbar-background {
    background-color: #f16f29; }
  .energy-academy .h5p-single-choice-set .h5p-joubelui-progressbar-background {
    background-color: #364A9A; }

.h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view {
  background-color: #e40012; }
  .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:hover, .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:focus {
    background-color: #7e000a; }
  .green .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view {
    background-color: #72b639; }
    .green .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:hover, .green .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:focus {
      background-color: #416821; }
  .orange .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view {
    background-color: #f16f29; }
    .orange .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:hover, .orange .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:focus {
      background-color: #a9420b; }
  .energy-academy .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view {
    background-color: #364A9A; }
    .energy-academy .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:hover, .energy-academy .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:focus {
      background-color: #1c264e; }

.h5p-question-feedback {
  color: #e40012; }
  .green .h5p-question-feedback {
    color: #72b639; }
  .orange .h5p-question-feedback {
    color: #f16f29; }
  .energy-academy .h5p-question-feedback {
    color: #364A9A; }

.joubel-simple-rounded-button {
  background-color: #e40012; }
  .joubel-simple-rounded-button:hover, .joubel-simple-rounded-button:focus {
    background-color: #7e000a; }
  .green .joubel-simple-rounded-button {
    background-color: #72b639; }
    .green .joubel-simple-rounded-button:hover, .green .joubel-simple-rounded-button:focus {
      background-color: #416821; }
  .orange .joubel-simple-rounded-button {
    background-color: #f16f29; }
    .orange .joubel-simple-rounded-button:hover, .orange .joubel-simple-rounded-button:focus {
      background-color: #darken #f16f29, 20%; }
  .energy-academy .joubel-simple-rounded-button {
    background-color: #364A9A; }
    .energy-academy .joubel-simple-rounded-button:hover, .energy-academy .joubel-simple-rounded-button:focus {
      background-color: #darken #364A9A, 20%; }
  .joubel-simple-rounded-button:active {
    position: relative;
    background-color: #364A9A;
    -webkit-box-shadow: inset 0 4px 0px #364A9A;
    -moz-box-shadow: inset 0 4px 0px #364A9A;
    box-shadow: inset 0 4px 0px #364A9A; }

.h5p-joubelui-score-bar-progress {
  background: #e40012; }

.energy-academy .block-ys-elearning-index .menu_arrow {
  background-image: url(../images/energy-academy/arrow.png);
  background-repeat: no-repeat;
  width: 36px;
  height: 27px;
  bottom: 20px;
  left: 306px; }

.energy-academy .block-ys-elearning-index .tab-wrapper {
  background: #364A9A; }

.energy-academy .block-ys-elearning-index .tab-content {
  background: #fff; }

.energy-academy .block-ys-elearning-index #tabs {
  border-bottom: none !important; }
  .energy-academy .block-ys-elearning-index #tabs ul {
    background: #fff; }
  .energy-academy .block-ys-elearning-index #tabs li {
    background: #fff;
    border-bottom: 1px solid #364A9A;
    color: #000;
    border-top: none;
    border-left: none !important;
    border-right: none !important; }
    .energy-academy .block-ys-elearning-index #tabs li.ui-state-active {
      border-top: 0;
      border-bottom: 1px solid #DF2127 !important; }
    .energy-academy .block-ys-elearning-index #tabs li a {
      color: #000;
      text-shadow: none; }

.energy-academy .block-ys-elearning-index .filterform {
  border-bottom: none; }

.energy-academy .block-ys-elearning-index .filterinput {
  background: #f5f5f5;
  box-shadow: none;
  text-shadow: none;
  color: #000; }

.energy-academy .block-ys-elearning-index h2 {
  color: #364A9A; }

.energy-academy .block-ys-elearning-index .tab-content-list {
  background: #fff;
  color: #000; }
  .energy-academy .block-ys-elearning-index .tab-content-list ul li a {
    color: #000; }
    .energy-academy .block-ys-elearning-index .tab-content-list ul li a:hover, .energy-academy .block-ys-elearning-index .tab-content-list ul li a:focus {
      background: #fff;
      color: #364A9A; }

.energy-academy .block-ys-elearning-index #training-definitions .tab-content-list li.first h2,
.energy-academy .block-ys-elearning-index #training-index .tab-content-list li.first h2 {
  border-top: none;
  border-bottom: 1px solid #2c2c2c; }

.energy-academy .block-ys-elearning-index #training-definitions .tab-content-list li dfn,
.energy-academy .block-ys-elearning-index #training-definitions .tab-content-list li a,
.energy-academy .block-ys-elearning-index #training-index .tab-content-list li dfn,
.energy-academy .block-ys-elearning-index #training-index .tab-content-list li a {
  border-top: none;
  border-bottom: 1px solid #2c2c2c; }
