$black: #000 !default;
$white: #fff;

$gray-100: #f5f5f9 !default;
$gray-200: #cecece !default;
$gray-900: #2c2c2c !default;

$aeb-red: #e40012 !default;
$aeb-dark-red: #B10000 !default;

$oof-green: #72b639 !default;
$oof-dark-green: #52822e !default;
$oof-red: #EF2751 !default;

$oof-orange: #f16f29 !default;
$oof-dark-orange: #ad440b !default;

$ea-blue: #364A9A !default;
$ea-light-blue: #6C85C2 !default;
$ea-red: #DF2127 !default;
